:root {
  /* BASE COLORS */
  --color-black: hsl(223, 9%, 15%);
  --color-black-soft: hsl(223, 9%, 12%);
  --color-white: hsl(0, 0%, 88%, 1);
  --color-white-soft: hsl(0, 0%, 50%, 1);
  --color-white-pure: hsl(0, 0%, 100%);

  /* BRAND COLORS */
  --color-brand-blue-light: hsl(214, 35%, 81%);
  --color-brand-blue: hsl(214, 67%, 52%);
  --color-brand-blue-dark: hsl(214, 61%, 22%);
  --color-brand-green: hsl(146, 40%, 65%);
  --color-brand-orange: hsl(33, 80%, 95%);
  --color-brand-5: hsl(203, 61%, 11%);
  --color-brand-6: hsl(203, 61%, 8%);

  /* GRAY COLORS*/
  --color-gray-1: hsl(0, 0%, 90%);
  --color-gray-2: hsl(0, 0%, 80%, 1);
  --color-gray-3: hsl(0, 0%, 60%);
  --color-gray-4: hsl(0, 0%, 30%);
  --color-gray-5: hsl(0, 0%, 11%);

  /* UTIL COLORS */
  --color-sucess: hsl(124, 47%, 75%);
  --color-negative: hsl(355, 67%, 95%);

  /* MISC COLORS */
  --color-link: hsl(214, 67%, 52%);

  /* FONT FAMILIES */
  --font-mukta: 'Mukta', sans-serif;
  --font-noto-sans: 'Noto Sans', sans-serif;

  /* FONT WEIGHTS */
  --weight-regular: 400;
  --weight-semi-bold: 600;
  --weight-extra-bold: 800;

  /* GLOBAL MEASUREMENTS */
  --dy-gutter: 16px;
  --dy-button-border-focus-radius: 6px;
  --dy-button-border-radius: 4px;

  /* TRANSITION */
  --global-transition: .1s ease-in;
}