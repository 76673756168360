.socials {
  display: flex;
}

.socials__link {
  padding: 8px;
  fill: var(--color-brand-green);

  &:hover,
  &:focus {
    fill: var(--color-link);
    outline: none;
  }
}

.socials__social {
  width: 24px;
  height: 24px;
  fill: inherit;
  transition: fill var(--global-transition);
}