.calculator {
  grid-gap: 24px;
}

.calculator__label {
  margin-bottom: .8em;
  display: block;
}

.calculator__input {
  padding: 8px 6px;
  max-width: 4em;
  background-color: transparent;
  border: 1px solid var(--color-white-soft);
  border-radius: 3px;
  color: var(--color-white-soft);
  text-align: center;
  transition: all var(--global-transition);

  &::selection {
    background: var(--color-white);
  }
  &[data-has-value=true] {
    background-color: var(--color-black-soft);
    color: var(--color-brand-white);
  }
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus {
    background-color: var(--color-black-soft);
    border-color: var(--color-brand-green);
    color: var(--color-brand-white);
    outline: none;
  }
}

.calculator__result {
  margin-top: 1.5em;
  padding: 16px;
  background-color: var(--color-black-soft);
  border-radius: 4px;
  text-align: center;

  &.is-positive {
    color: var(--color-sucess);
  }
  &.is-negative {
    color: var(--color-negative);
  }
}

.calculator__result__heading {
  margin-bottom: 1.2em;
  color: var(--color-brand-blue-light);
}

.calculator__result__output {
  display: inline-block;
  &:not(:last-of-type) {
    margin-bottom: .5em;
    margin-right: 50px;
  }
}