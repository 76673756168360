.footer {
  margin-right: 16px;
  margin-left: 16px;
  padding: 2em 16px;
  grid-area: footer;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-top: 1px solid var(--color-white-soft);
  color: var(--color-white-soft);

  @media (max-width: 799px) {
    flex-direction: column;
  }
}

.footer__heading {
  @media (max-width: 799px) {
    margin-bottom: .5em;
  }
  @media (min-width: 800px) {
    margin-right: .5em;
  }
}

.footer__copy {
  @media (max-width: 799px) {
    margin-top: 1em;
  }
  @media (min-width: 800px) {
    margin-left: auto;
  }
}