.header {
  grid-area: header;
  display: flex;
  align-items: baseline;
  border-bottom: var(--color-black-soft) solid;

  @media (max-width: 599px) {
    margin-bottom: 1em;
    padding: 1em 16px 1em 16px;
    border-width: 4px;
  }
  @media (min-width: 600px) {
    margin-bottom: 1em;
    padding: 2em 16px 2em 16px;
    border-width: 8px;
  }
}

.header__display {
  margin-right: auto;
  margin-left: 16px;
  color: var(--color-brand-blue-light)
}

.header__logo {
  height: auto;
  fill: var(--color-brand-blue-light);

  @media (max-width: 599px) {
    width: 26px;
  }
  @media (min-width: 600px) {
    width: 34px;
  }
}